import React, { useMemo } from "react"

import { CubeIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"
import { Image } from "~/src/components/Image"

export type PhotoImageProps = {
  className?: string
  srcs?: (string | undefined)[]
}

/**
 * Renders an image component.
 *
 * @param props The component props.
 * @param props.className The CSS class name for the image component.
 * @param props.srcs The list of image sources to use for the image component.
 * @returns The rendered image component.
 */
export function ImageSet(props: PhotoImageProps) {
  const { className, srcs } = props
  const [first] = srcs ?? []

  const srcSet = useMemo(
    () =>
      srcs
        ? srcs
            ?.map((src, index) => [src, index + 1])
            .map(([src, scale]) => `${src} ${scale}x`)
            .join(", ")
        : "",
    [srcs]
  )

  return (
    <Image src={first} srcSet={srcSet} className={twMerge("h-full w-full object-contain object-center", className)}>
      <div
        role="img"
        className={twMerge("h-full w-full flex items-center justify-center text-gray-400 bg-gray-100", className)}
      >
        <CubeIcon className="h-10 w-10 stroke-1" />
      </div>
    </Image>
  )
}
